<template>
  <div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
    <div v-if="loading">
      <div id="loading-bg">
        <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </div>
    <b-card v-else>
      <!-- input search -->
      <div class="custom-search">
        <b-row>
          <b-col md="3">
            <b-form-group>
              <b-button variant="primary" :to="{ name: 'BL-add' }">
                Nouveau Bon Livraison
              </b-button>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group v-if="SelectedRows.length!=0">
              <b-button variant="success" v-b-toggle
               href="#add-user-sidebar"
               @click.prevent="showBL(props.row)">
                Ajouter au facture
              </b-button>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Chercher </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Chercher"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group>
              <div class="d-flex align-items-center">
                <!-- <label class="mr-1"> </label> -->
                <b-form-input
                  v-model="ClientBL"
                  placeholder="Chercher le client pour facturér ces bons"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
             <b-button variant="warning" 
               @click="SearchClient">
                <feather-icon icon="SearchIcon" />
                <!-- Chercher Client -->
              </b-button>
            
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <vue-good-table
        styleClass="vgt-table bordered mytable"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        responsive
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        
        :select-options="{
          selectOnCheckboxOnly: true,
          enabled: EnableCheck ,
          clearSelectionText: 'Annuler',
          selectionText: 'Bons Sélectionés',
          disableSelectInfo:true,
          }"
        @on-selected-rows-change="selectionChangedBL"
        
        
        
      >

      <!-- :select-options="{
           vgtDisabled:true,
          selectOnCheckboxOnly: true,
          enabled: true ,
          clearSelectionText: 'Annuler',
          selectionText: 'Bons Sélectionés',
          disableSelectInfo:true,
          }"
        @on-selected-rows-change="selectionChangedBL" -->
      
<!-- @on-select-all="onSelectAllBL" -->
      <!-- :select-options="{
          selectOnCheckboxOnly: true,
          enabled: true ,
          clearSelectionText: 'Annuler',
          selectionText: 'Bons Sélectionés',
          disableSelectInfo:true,
          selectAllByGroup:true
          }"
        @on-select-all="onSelectAllBL"
        @on-selected-rows-change="selectionChangedBL" -->
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'remise'" class="text-nowrap">
            <span class="text-nowrap">{{ props.row.remise }} %</span>
          </div>
          <!-- <div v-else-if="props.column.field === 'check'" class="text-nowrap">
            <input type="checkbox" v-on:change.prevent="SelectedBL(props.row)" >
          </div> -->
          <span v-else-if="props.column.field === 'direct'">
            <b-badge :variant="statusVariant(props.row.direct)">
              <template v-if="props.row.direct === 1">Direct</template>
              <template v-else>Indirecte</template>
            </b-badge>
          </span>
          <div v-else-if="props.column.field === 'NumBL'">
            <b-avatar
              :id="`invoice-row-${props.row.id}`"
              size="32"
              :variant="`light-${getStatuDevis(props.row.IDFact).variant}`"
            >
              <feather-icon :icon="getStatuDevis(props.row.IDFact).icon" />
            </b-avatar>
            <b-tooltip :target="`invoice-row-${props.row.id}`" placement="top">
              <p class="mb-0">
                {{ props.row.NumBL }}
              </p>
              <p class="mb-0" v-if="props.row.IDFact !== null">
                Est un : Facture
              </p>
              <p class="mb-0" v-else-if="props.row.IDFact === null">
                Est un : Bon de livraison
              </p>
              <p class="mb-0">La Date: {{ getDate(props.row.DateBL) }}</p>
            </b-tooltip>
            <span class="text-nowrap">{{ props.row.NumBL }} </span>
          </div>
          <div v-else-if="props.column.field === 'TotalHT'" class="text-nowrap">
            <span class="text-nowrap">{{ props.row.TotalHT }} Dhs</span>
          </div>
          <div
            v-else-if="props.column.field === 'TotalNet'"
            class="text-nowrap"
          >
            <span class="text-nowrap"
              ><b>{{ props.row.TotalNet }} Dhs</b></span
            >
          </div>
          <!-- <span v-else-if="props.column.field === 'bl'">
           
            <b-button
                variant="gradient-danger"
                class="btn-icon"
                @click="TransformDevis(props.row.id)"
                
                >
                <feather-icon icon="XIcon" />
                        
            </b-button>
            </span> -->
          <span v-else-if="props.column.field === 'fact'">

            <b-button
              v-if="props.row.IDFact !== null"
              variant="gradient-danger"
              class="btn-icon"
              disabled
              v-b-toggle
              href="#add-user-sidebar"
              @click.prevent="showBL(props.row)"
            >
              <!-- @click="TransformFact(props.row.id)" -->
              <feather-icon icon="NavigationIcon" />
              <!-- <i data-feather='navigation'></i> -->
            </b-button>
            <template v-if="!props.row.vgtSelected">
            <b-button
              v-if="props.row.IDFact === null"
              variant="gradient-info"
              class="btn-icon"
              v-b-toggle
              href="#add-user-sidebar"
              @click.prevent="showBL(props.row)"
            >
              <feather-icon icon="NavigationIcon" />
            </b-button>
            </template>
          </span>
          <!-- fact -->
          <span v-else-if="props.column.field === 'DateBL'">
            {{ getDate(props.row.DateBL) }}
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- <b-dropdown-item @click="$router.push({ name: 'devis-preview', params: { id: props.row.id }})"  >
                    <feather-icon
                icon="EyeIcon"
                size="16"
                class="mr-50"
                
            />
            <span>Voir</span>
            
                </b-dropdown-item> -->
                <b-dropdown-item @click="GetBLPDF(props.row)">
                  <feather-icon icon="FileIcon" class="mr-50" />
                  <span>Télécharger</span>
                </b-dropdown-item>
                <template v-if="props.row.paid != 1">
                  <b-dropdown-item
                    @click="
                      $router.push({
                        name: 'bl-update',
                        params: { id: props.row.id },
                      })
                    "
                  >
                  <!--  @click="
                      $router.push({
                        name: 'devis-update',
                        params: { id: props.row.id },
                      })
                    " -->
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Modifie</span>
                  </b-dropdown-item>
                </template>

                <b-dropdown-item @click="TransformDevis(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['25', '50', '100']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <b-sidebar
      id="add-user-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Transformer ce BL en Facture</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form id="formdata" class="p-2" @submit.prevent>
          <b-form-group>
            <b-form-input
              v-model="form.NumFactClient"
              @change="CheckNumFact"                       
            />
            <template v-if="errorFact">
              <small class="text-danger">Ce Numéro est déjà existe</small>
            </template>
          </b-form-group>

          <b-form-group label="Date Facture">
              <b-form-input v-model="form.dateFact" name="date" type="date" />
          </b-form-group>
          <b-form-group style="margin-bottom: 2rem" label="Reglement">
            <v-select
              style="border: 1px solid #0000002b; border-radius: 0.357rem"
              v-model="form.reglement"
              label="title"
              :options="optionRoles"
            />
          </b-form-group>
          <template v-if="form.reglement.value == 'Chèque' || form.reglement.value == 'Effet'">
            <b-form-group label="Numéro Chèque">
              <b-form-input v-model="form.NumCheq" name="numcheq" />
            </b-form-group>
            <b-form-group label="BQ">
              <b-form-input v-model="form.BQ" name="bq" />
            </b-form-group>
          </template>
          <b-form-group label="La date" v-if="form.reglement.value != 'Pas Encore'">
              <b-form-input type="date" v-model="form.dateReglement" name="date" />
            </b-form-group>
           <template v-if="form.reglement.value=='Chèque' || form.reglement.value ==='Virement' || form.reglement.value ==='Effet'">
              <b-form-group style="margin-bottom: 2rem" label="La banque">
              <v-select
                v-model="form.banque"
                label="title"
                :options="optionBanqs"
              />
            </b-form-group>
          </template>
          <b-form-group label="Payée" label-for="paid">
            <b-form-checkbox
              v-model="form.paid"
              class="custom-control-info"
              name="check-button"
              value="1"
              unchecked-value="0"
              switch
            />
          </b-form-group>
          <!-- <template v-if="form.reglement.value == 'Cheque'">
            <b-form-group label="Numéro Cheque">
              <b-form-input v-model="form.NumCheq" name="numcheq" />
            </b-form-group>
            <b-form-group label="BQ">
              <b-form-input v-model="form.BQ" name="bq" />
            </b-form-group>
          </template>
          <b-form-group label="Payée" label-for="paid">
            <b-form-checkbox
              v-model="form.paid"
              class="custom-control-info"
              name="check-button"
              value="1"
              unchecked-value="0"
              switch
            />
          </b-form-group> -->
          <b-form-group label="Fournis au client" label-for="livred">
            <b-form-checkbox
              class="custom-control-warning"
              name="check-button"
              switch
              v-model="form.livred"
              value="1"
              unchecked-value="0"
            />
          </b-form-group>
          <b-form-group label="Note :" label-for="note">
            <b-form-textarea
              placeholder="Note Facture"
              rows="2"
              max-rows="8"
              v-model="form.note"
            />
          </b-form-group>
          <b-form-group label="Les pièces jointes" label-for="pj">
            <input
              type="file"
              accept="application/pdf,image/*"
              class="hidden"
              ref="updateImgInput"
              multiple="multiple"
              @change="imageSelected"
            />
            <b-button
              style="margin-bottom: 20px"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              block
              @click="$refs.updateImgInput.click()"
            >
              Importer Pièces Jointes
            </b-button>
          </b-form-group>
          <b-form-group>
            <b-media-aside>
              <div v-for="(attachment, index) in attachments" :key="index">
                <span class="label label-primary"
                  >{{
                    attachment.name +
                    " (" +
                    Number((attachment.size / 1024 / 1024).toFixed(1)) +
                    "MB)"
                  }}<br
                /></span>
              </div>
            </b-media-aside>
          </b-form-group>

          <!--  -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="TransformFact()"
            >
              Sauvegarder
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="annuler()"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
  <div v-else>
    <not-authoriz></not-authoriz>
  </div>
</template>

<script>
import config from "@/config";
import moment from "moment";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BButton,
  VBToggle,
  BCollapse,
  BSidebar,
  BForm,
  BFormCheckbox,
  BMediaAside,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
//  import SidebarAddProduct from './SidebarAddProduct.vue'
import vSelect from "vue-select";
import NotAuthoriz from "../NotAuthoriz.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCard,
    BCollapse,
    BCardCode,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    vSelect,
    BSidebar,
    BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BButton,
    BFormCheckbox,
    BMediaAside,
    BFormTextarea,
    NotAuthoriz,
    // SidebarAddProduct,
  },
  data() {
    return {
      perms: [],
      loading: false,
      showSide: true,
      pageLength: 10,
      dir: false,
      UserID: {},
      ClientBL:null,
      columns: [
        // {
        //   label: "Fact",
        //   field: "check",
        // },
        {
          label: "N° BL",
          field: "NumBL",
          filterOptions: {
            enabled: true,
            placeholder: "Chercher ",
          },
        },
        {
          label: "BL Direct",
          field: "direct",
          filterOptions: {
            enabled: true,
            placeholder: "BL Direct .. ",
          },
        },
        {
          label: "Nom Client",
          field: "NomSociete",
          filterOptions: {
            enabled: true,
            placeholder: "Chercher ",
          },
        },

        {
          label: "Total H.T",
          field: "TotalHT",
          filterOptions: {
            enabled: true,
            placeholder: "Chercher..",
          },
        },
        {
          label: "Remise %",
          field: "remise",
          filterOptions: {
            enabled: true,
            placeholder: "Chercher..",
          },
        },
        {
          label: "Total NET",
          field: "TotalNet",
          filterOptions: {
            enabled: true,
            placeholder: "Chercher..",
          },
        },
        {
          label: "Date",
          field: "DateBL",
          filterOptions: {
            enabled: true,
            placeholder: "N° client.. ",
          },
        },

        // {
        //   label: 'Devis',
        //   field: 'bl',
        // },
        {
          label: "Facture",
          field: "fact",
        },

        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      optionRoles: [
        { title: "Pas Encore", value: "Pas Encore" },
        { title: "Espèce", value: "Espèce" },
        { title: "Chèque", value: "Chèque" },
        { title: "Virement", value: "Virement" },
        { title: "Effet", value: "Effet" },
      ],
      optionBanqs: [
        { title: "BMCI", value: "BMCI"},
        { title: "CIH", value: "CIH"},
        { title: "Banque Populaire", value: "Banque Populaire"},
        { title: "BMCE", value: "BMCE"},
        // { title: "Al Barid Bank", value: "Al Barid Bank"},
      ],
      attachments: [],
      form: new Form({
        id: 0,
        reglement: { title: "Pas Encore", value: "Pas Encore" },
        paid: 0,
        livred: 0,
        NumCheq: "",
        BQ: "",
        note:'',
        dateReglement:new Date().toISOString().slice(0,10),
        banque:{ title: "", value: "" },
        dateFact:new Date().toISOString().slice(0,10),
        NumFactClient:''
      }),
      SelectedRows:[],
      SelectedRows2:[],
      CheckedValue:[],
      idClient:{},
      currentSelections:null,
      EnableCheck:false,
      FilterValue:[],
      DataRow:[],
      errorFact:false
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Chèque: "light-success",
        ESPECE: "light-warning",
        CHEQUE: "light-success",
        espece: "light-warning",
        1: "light-success",
        0: "light-danger",
        3: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.perms = JSON.parse(localStorage.getItem("access"));
    this.getBL();
    this.UserID = JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    getBL() {
      this.loading = true;
      this.$http
        .get(`${config.API_BASE_URL}/api/auth/getBLClients`)
        .then((res) => {
          (this.rows =this.DataRow = res.data), (this.loading = false);
        });
    },
    GetCheckedRow(){

    },

    CheckNumFact(){
      this.$http
        .post(`${config.API_BASE_URL}/api/auth/CheckNumFact?numFact=${this.form.NumFactClient}`)
        .then((res) => {
            if(res.data=='exist'){
              this.errorFact=true;
            }else if(res.data=='success'){
              this.errorFact=false;
            }
        });
      // console.log(this.NumFact)
    },
    SearchClient(){
      if(this.ClientBL){
        this.FilterValue=this.rows.filter(obj => obj.NomSociete.toUpperCase()===this.ClientBL.toUpperCase() && obj.IDFact===null);
        if(this.FilterValue.length>0){
          this.EnableCheck=true;
          this.rows=this.FilterValue;
          console.log(this.rows)
        }else{
          // this.getBL();
          this.rows=this.DataRow;
          this.EnableCheck=false;
          this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Aucun client existe',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
        }})
        }
      }else{
        // this.getBL();
          this.rows=this.DataRow;
        this.EnableCheck=false;
        this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Aucun client existe',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
        }})
      }
      
      // const obj=this.rows.find(el => el.NomSociete.toUpperCase() === this.ClientBL.toUpperCase())
      // if(obj){
      //   this.EnableCheck=true;
      //   if(obj.IDFact===null){
      //     obj.vgtSelected=true;
      //   }
       

      // }
    },
    // onSelectAllBL(params) {
    //   this.SelectedRows2=params.selectedRows
    //   this.idClient=this.SelectedRows2[0];
    //   for(var j=0 ; j<this.SelectedRows2.length ; ++j){
    //   //   // console.log(this.SelectedRows2[j].client_id)
    //     if(this.SelectedRows2[j].IDFact!=null){
    //   //     // this.SelectedRows2[j].vgtSelected=false;
    //       const index = this.SelectedRows2.indexOf(this.SelectedRows2[j]);
    //       if (index > -1) {
    //         this.SelectedRows2.splice(index, 1); // 2nd parameter means remove one item only
    //       }

    //     }else{
    //   //     const obj=this.CheckedValue.find(el => el.client_id != this.SelectedRows[j].client_id)
    //   //     if(!obj){
    //   //       this.CheckedValue.push(this.SelectedRows[j]);
    //   //     }
    //   //     // this.SelectedRows[j].hide=true;
    //     }
    //   }
    //   console.log(this.SelectedRows2);
    // },
    selectionChangedBL(params,client){
      console.log(params)
      this.SelectedRows=params.selectedRows
      if(this.SelectedRows.length==1){
        this.idClient=this.SelectedRows[0];
      }else{
        this.idClient=this.SelectedRows[0];
      }
      for(var j=0 ; j<this.SelectedRows.length ; ++j){
        if(this.SelectedRows[j].IDFact != null){
          console.log(this.SelectedRows[j])
          this.SelectedRows[j].vgtSelected=false;
          const index = this.SelectedRows.indexOf(this.SelectedRows[j]);
          if (index > -1) {
            this.SelectedRows[j].vgtSelected=false;
            this.SelectedRows.splice(index, 1); // 2nd parameter means remove one item only
          }
      }
      }
      this.CheckedValue = this.SelectedRows.filter(obj => obj.client_id===this.idClient.client_id && obj.IDFact===null);
      console.log(this.CheckedValue)
      console.log(this.SelectedRows)
      if(this.CheckedValue.length!=this.SelectedRows.length){
         this.$swal.fire({
          text: "Vous pouvez pas ajouter des bons facturés ou des clients différents",
          icon: "error",
          // title: "Oops...",
        })
      }
    },
    getDate(value){
      return moment(String(value)).format("DD/MM/YYYY");
    },
    GetBLPDF(row){
      this.$http
        .get(`${config.API_BASE_URL}/api/auth/GetBLPDF/${row.id}}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "BonLivraison_N" + row.NumBL + ".pdf";
          link.click();
        });
    },

    showBL(row){
      if(this.CheckedValue.length!=this.SelectedRows.length){
         this.$swal.fire({
          text: "Vous pouvez pas ajouter des bons facturés ou des clients différents",
          icon: "error",
          // title: "Oops...",
        })
      }else{
        this.form.reset();
        this.form.id = row.id;
        this.$http
          .get(`${config.API_BASE_URL}/api/auth/GetNumFact`)
          .then((res) => {
            this.form.NumFactClient = res.data;
        });
      }
      
    },
    imageSelected(e) {
      let selectedFiles = e.target.files;
      if (!selectedFiles.length) {
        return false;
      }
      for (let i = 0; i < selectedFiles.length; i++) {
        this.attachments.push(selectedFiles[i]);
      }
      // console.log(this.attachments);
    },
    annuler() {
      document.getElementById("formdata").reset();
      this.attachments = [];
      this.form.reglement.value = "";
    },
    TransformFact(){
      if(this.CheckedValue.length!=this.SelectedRows.length){
         this.$swal.fire({
          text: "Vous pouvez pas ajouter des bons facturés ou des clients différents",
          icon: "error",
          // title: "Oops...",
        })
      }else if(this.errorFact){
        this.$swal.fire({
              icon: 'error',
              title: 'Numéro déjà existe',
              text: 'Le numéro de la facture est déjà existe' 
        })
      }else{
        this.$swal
        .fire({
          text: "Vous voulez vraiment ajouter ces bons dans cette facture ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, ajouter",
        })
        .then((result) => {
          if (result.value) {
            let data = new FormData();
            data.append("UserID", this.UserID.id);
            data.append("reglement", this.form.reglement.value);
            data.append("paid", this.form.paid);
            data.append("livred", this.form.livred);
            data.append("id", this.form.id);
            data.append("NumFact", this.form.NumFactClient);
            data.append("note", this.form.note);
            data.append("NumCheq", this.form.NumCheq);
            data.append("BQ", this.form.BQ);
            data.append("dateReglement", this.form.dateReglement);
            data.append("banque", this.form.banque.value);
            data.append("dateFact", this.form.dateFact);
            data.append('listBl', JSON.stringify(this.CheckedValue));
            for (let i = 0; i < this.attachments.length; i++) {
              data.append("attachments[]", this.attachments[i]);
            }
              console.log(Object.fromEntries(data))
            this.$http
              .post(
                `${config.API_BASE_URL}/api/auth/BLToFact/${this.form.id}`,
                data
              )
              .then((res) => {
                // console.log(res.data)
                if (res.data == "Success") {
                  document.getElementById("formdata").reset();
                  this.attachments = [];
                  this.form.reglement.value = "";
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Ce bon de livraison est bien transformé en facture",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getBL();
                } else if(res.data == "exist") {
                  this.$swal.fire({
                    icon: "error",
                    title: "Numéro facture ",
                    text: `Ce numéro da facture est déjà existe`,
                  });
                }else {
                  this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Erreur de transformation ! Merci d'essayer encore.`,
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong! Pleaz try again",
                });
              });
          }
        });
      }
      
    },
    TransformDevis(id) {
      let data = new FormData();
      data.append("UserID", this.UserID.id);
      this.$swal
        .fire({
          text: "Vous voulez vraiment supprimer ce Bon de livraison?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprimer",
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(`${config.API_BASE_URL}/api/auth/BLToDevis/${id}`, data)
              .then((res) => {
                if (res.data == "Success") {
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Le bon de livraison est bien supprimé",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getBL();
                } else if (res.data == "exist") {
                  this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Ce bon de livraison est déjà transformer en facture.`,
                  });
                } else {
                  this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Erreur! Merci d'essayer encore.`,
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong! Pleaz try again",
                });
              });
          }
        });
    },
    getStatuDevis(status) {
      if (status !== null)
        return { variant: "success", icon: "CheckCircleIcon" };
      if (status === null)
        return { variant: "danger", icon: "ArrowDownCircleIcon" };

      return { variant: "secondary", icon: "XIcon" };
    },

    containsObject(item){
     var i;
      for (i = 0; i < this.CheckedValue.length; i++) {
          if (this.CheckedValue[i] === item) {
              return true;
          }
      }
      return false;
    }
  },
   
};
</script>
<style scoped>
.media-aside.align-self-start {
  display: grid;
}
.form-group {
  margin-bottom: 2rem;
}
[dir] .vs__dropdown-toggle {
  padding: 0 0 4px 0;
  background: none;
  border: 1px solid #0000002b;
  border-radius: 0.357rem;
}
.vs__dropdown-toggle {
  background: none;
  border: 1px solid #0000004f;
  border-radius: 0.357rem;
}



</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
